












































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import ItemTags from '@/components/tag/ItemTags.vue'
import AddTag from '@/components/tag/AddTag.vue'

@Component({
  components: {
    AddTag,
    ItemTags,
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private listViewKey = 0
  private loadingData = false
  private loading = false

  private headers = []
  private topActions = []
  private rowActions = []

  private addNewTagDialog = false

  private created() {
    this.setHeaders()
    this.setActions()
  }

  private get url() {
    return this.mainUrl
  }

  private get mainUrl() {
    return '/v4/site/tags'
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'c:list-tag:Name',
        value: 'name',
        filter: { disable: false },
      },
      {
        text: 'c:list-tag:Icon',
        value: 'icon',
        filter: { disable: false },
      },
      {
        text: 'c:list-tag:Type',
        value: 'type',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'c:list-tag:Preview',
        value: 'preview',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'c:common:Actions',
        value: 'actions',
      },
    ]
  }

  private setActions() {
    this.topActions = [
      {
        id: 'new',
        template: 'new',
        click: () => {
          this.addNewTagDialog = true
        },
      },
    ]

    this.rowActions = [
      {
        id: 'delete',
      },
    ]
  }

  private onNewTag() {
    this.listViewKey += 1
  }
}
